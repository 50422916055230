import './assets/css/salesbeat_base.css'
// var process = {env: {BUILD_HASH: 2384234, DJANGO_HOST: 'https://app.salesbeat.pro/'}}
// var process = {env: {BUILD_HASH: 2384234, DJANGO_HOST: 'https://salesbeat.local:8000'}}


if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || 
                                Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

function getCharCodes(s){
    var codes = [];
    for (var i=0; i<s.length; i++){
        codes.push(s.charCodeAt(i));
    }
    return codes;
}

function getStringFromCharCodes(codes){
    var s = [];
    for (var i=0; i<codes.length; i++){
        s.push(String.fromCharCode(codes[i]));
    }
    return s.join('');
}


(function() {
    if (window.salesbeatBeganLoadingLock === true) return
    window.salesbeatBeganLoadingLock = true

    const SB = {
        widgets: []
    }

    let quantityControlsInitialized = false

    let hash = process.env.BUILD_HASH

    const h = process.env.DJANGO_HOST

    let customStyleAndScriptLoaded = false;
    let commonStyleAndScriptLoaded = false;

    SB.token = null

    const yaMaps = {
        apiLoadingBegan: false,
        ready: false,
        onReady: null,
        cachedBounds: null, 
        _deletedGeoObjects: {}
    }
    SB.yaMaps = yaMaps

    const apiUrl = `${h}/widget/get/`;
    const pvzUrl = `${h}/widget/pvzs/get/`;
    const pvzInfoUrl = `${h}/widget/pvzs/info/`;
    const GET_CITY_URL = `${h}/api/v1/get_cities_for_cities_widget`;

    const yandexApiKeys = {
        'toptop.ru': '77601eaf-37cf-441f-89f6-67f633657f48',
        'kkvolos.ru': 'be7e3779-6355-45dd-91ee-8ad8b1c3ffdf',
        'gold-standart.com': '171b8030-4637-46eb-ac19-792c4a9e5205',
        'neoclas.ru': '8689cc3d-f3b1-46ad-a112-9a1edad4b312',
        'neoclas.tilda.ws': '8689cc3d-f3b1-46ad-a112-9a1edad4b312',
        'pastila-mila.ru': 'd5790bd6-868d-405c-b1fb-94606fe2d99f',
        'stelom.ru': 'c8cbed43-fa72-479f-a09b-0ae73a5823c1'
    };
    const yandex_api_key = yandexApiKeys[document.location.host];

    const srcMap = {
        wdelCss: `${h}/static/widget/css/widget.css`,
        wdelScript: `${h}/static/widget/js/wdel.js`,
        yaMaps: 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&ns=sbYmaps' + 
            (yandex_api_key ? `&apikey=${yandex_api_key}` : '')
    };
    SB.pvzUrl = pvzUrl
    SB.pvzInfoUrl = pvzInfoUrl
    SB.srcMap = srcMap

    const defaultMainDivId = 'salesbeat-deliveries';

    const warnPhrase = 'Ошибочка в Salesbeat'

    let getStyles = (backgroundColor) => (
        `color: white; background-color: ${backgroundColor}; border-radius: 5px;` + 
        'padding: 3px; padding-left: 5px; padding-right: 5px;'
    )
    let consoleStyles = getStyles('#64ad1b')
    let errorStyles = getStyles('#c80813')

    // Поприветсвуем открывателей инспект-элемента
    console.log('%cИнформацию о доставке показывает Salesbeat', consoleStyles)
    console.log('Документация https://salesbeat.pro/docs, партнёрка https://salesbeat.pro/partners')

    const _log = message => console.log(`%c${warnPhrase}:%c ${message}`, 
        // Раскрасим красивенько вывод
        consoleStyles, '')
    SB.log = _log

    // let widgetRenderedEvent = new Event('sbWidgetRendered')
    // Events:
    SB.widgetRenderedEventName = 'sbWidgetRendered'
    SB.cartWidgetRenderedEventName = 'sbCartWidgetRendered'
    SB.cityChangedEventName = 'sbCityChanged'
    SB.deliveryChangedEventName = 'sbDeliveryChanged'
    SB.deliveryApprovedEventName = 'sbDeliveryApproved'

    var widgetPvzMapId = 'sb-pvz-map'
    SB.myMaps = {}

    let domLoaded = false

    // Для поддержки IE8,9
    var XHR = ("onload" in new XMLHttpRequest()) ? XMLHttpRequest : XDomainRequest;
    SB.XHR = XHR;
    
    /**
    *   Инициализация виджета:   
    *
    *   :param options: Object с полями:
    *       token: обязательное поле,
    *       city_code: опциональное, 
    *       params_by: 'product_id', 'products_list' или 'params', обязательное поле,
    *       product_id: обязательное поле при ``params_by`` == 'product_id',
    *       weight: вес товара, обязательное поле при ``params_by`` == 'params',
    *       x: размер товара X, опциональное поле,
    *       y: размер товара Y, опциональное поле,
    *       z: размер товара Z, опциональное поле,
    *       price_to_pay: цена товара к оплате, обязательное поле
    *       price_insurance: цена страховки товара, обязательное поле
    *       products: опциональное поле, Array вида:
    *           [{x: 300, y: 20, z: 10, weight: 240, price_to_pay: 3000, price_insurance: 8000},
    *            {product_id: '13342', price_to_pay: 3000, price_insurance: 8000}...]
    **/

    SB.init = function(options) {
        // Для совместимости со старыми и странными интеграшками
        // (например, textil-domoi.ru передают city_by=ip и тут же передают city_code)
        if (options.city_by == 'ip') {
            delete options.city_by
            delete options.city_code
        }

        options.main_div_id = options.main_div_id || defaultMainDivId
        let optionsCopy = Object.assign({}, options)

        if (!validateOptions(options)) {
            return;
        }

        if (SB.token != options.token) SB.token = options.token

        // Если код города не передан – попробуем взять его из кук
        if (!options.city_code) options.city_code = SB.getSelectedCity(options)

        getData(options)

        let oldState = SB.widgets.find(item => item.options.main_div_id === options.main_div_id)
        if (oldState) {
            oldState.loaded = false
            oldState.options = optionsCopy
        } else {
            SB.widgets.push({
                options: optionsCopy,
                loaded: false,
                html: null
            })
        }
    }

    SB.reinit = function(city_code) {
        for (let item of [...SB.widgets]) {
            // Не стоит вызывать callback при переинициализации
            item.options.callback = null

            if (city_code) item.options.city_code = city_code
            SB.init(item.options)
        }
    }
    
    function validateOptions(options) {
        if (!options || !options.token) {
            _log('Хотелось бы увидеть Ваш токен...')
            return false;
        }

        if (options.params_by === 'product_id') {
            if (!options.product_id) {
                _log(`Необходимо передать параметр "options.product_id".`)
                return false
            }
        } else if (options.params_by === 'params') {
            options.weight = Number(options.weight);
            options.x = options.x ? Number(options.x) : null;
            options.y = options.y ? Number(options.y) : null;
            options.z = options.z ? Number(options.z) : null;
            options.price_to_pay = Number(options.price_to_pay);
            options.price_insurance = Number(options.price_insurance);

            if (Number.isNaN(options.weight) || Number.isNaN(options.price_to_pay)
                    || Number.isNaN(options.price_insurance)) {
                _log(`Необходимо заполнить каждый из параметров: 
                    "options.weight", "options.price_to_pay" и "options.price_insurance"`)
                return false
            }
        } else if (options.params_by === 'product_list') {
            if (!options.products) {
                _log(`Необходимо передать параметр "options.products"`)
            }
        } else {
            _log(`Передано неверное значение параметра "options.product_id"
                "(возможные значения: 'product_id', 'params').`)
            return false
        }
        return true
    }

    SB.getSelectedCity = function(options) {
        return getCookie('sbSelectedCity') || null
    }

    function getCookie(name) {
        var matches = document.cookie.match(
            new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)")
        )
        return matches ? decodeURIComponent(matches[1]) : undefined
    }
    SB.getCookie = getCookie

    SB.setCookie = function(name, value, options) {
        options = options || {};

        if (!options.expires) {
            options.expires = 60 * 60 * 24 * 365;  // По умолчанию – поставим на год
        }
        if (!options.path) {
            options.path = '/';
        }

        var expires = options.expires;

        if (typeof expires == "number" && expires) {
            var d = new Date();
            d.setTime(d.getTime() + expires * 1000);
            expires = options.expires = d;
        }
        if (expires && expires.toUTCString) {
            options.expires = expires.toUTCString();
        }

        value = encodeURIComponent(value);

        var updatedCookie = name + "=" + value;

        for (var propName in options) {
            updatedCookie += "; " + propName;
            var propValue = options[propName];
            if (propValue !== true) {
                updatedCookie += "=" + propValue;
            }
        }

        document.cookie = updatedCookie;
    }

    SB.deleteCookie = function(name) {
        SB.setCookie(name, "", {
            expires: -1
        })
    }
    
    const loadCommonStyleAndScript = () => {
        if (!commonStyleAndScriptLoaded) {
            commonStyleAndScriptLoaded = true;

            loadCSS(srcMap.wdelCss);
            loadScript(srcMap.wdelScript);
        }
    };

    SB.init_city_choice = ({ token, city_choice_element_id, short_name_showed=true }) => {
        if (!token || !city_choice_element_id) {
            _log('не передан параметр token или city_choice_element_id, надо передать:) документация — https://salesbeat.pro/docs');
            return;
        }

        loadCommonStyleAndScript();

        send(GET_CITY_URL, {
            token,
            id: SB.getSelectedCity()
        }, (response) => {
            let city_name = response.cities[0].name;
            let city_short_name = response.cities[0].short_name;
            let widget = document.getElementById(city_choice_element_id);
            if (!widget){
                _log('не найден DOM элемент с переданным id '+city_choice_element_id);
                return;
            }
            function drawWidget(widget, city_name, city_short_name, short_name_showed){
                let a_text = '';
                if (short_name_showed){
                    a_text = `${city_short_name}. ${city_name}`
                } else {
                    a_text = `${city_name}`
                }
                widget.innerHTML = '<a href="#" class="salesbeat-city-choice-widget" onclick="SB.modal.cities.show(); return false;">'+a_text+'</a>';
            }
            drawWidget(widget, city_name, city_short_name, short_name_showed);
            document.addEventListener('sbCityChanged', function(event){
                drawWidget(widget, event.detail.city_name, event.detail.short_name, short_name_showed);
            });
        });
    }

    function reinitWidgetWithQuantity(main_div_id, quantity){
        let options = SB.widgets.find(item => item.options.main_div_id === main_div_id).options;
        options.quantity = (1 * quantity) || 1;
        SB.init(options);
    }

    function onQuantityChange(event){
        let element = event.target,
            counter_element  = element.closest('[data-sb-quantity]'),
            main_div_id = counter_element.getAttribute('data-sb-widget-id'),
            quantity = counter_element.querySelector('[data-sb-quantity-input]').value;
        reinitWidgetWithQuantity(main_div_id, quantity);
    }

    function getData(options) {
        send(apiUrl, options, (response) => {
            const widget = SB.widgets.find(item => item.options.main_div_id === options.main_div_id)
            widget.html = response.html
            widget.loaded = true
            widget.deliveries = response.deliveries

            render(widget)
            options.callback && options.callback()

            if (!quantityControlsInitialized){
                // After change quantity on page we need to recalculate delivery
                let sb_quantity_input_el = document.querySelector('[data-sb-quantity-input]');
                let sb_quantity_control_el = document.querySelectorAll('[data-sb-quantity-control]');
                sb_quantity_input_el && sb_quantity_input_el.addEventListener('input', onQuantityChange);
                if (sb_quantity_control_el && sb_quantity_control_el.length){
                    sb_quantity_control_el.forEach(function(el){
                        el.addEventListener('click', function(e){
                            setTimeout(function(){onQuantityChange(e)}, 400);
                        })
                    });
                }
                quantityControlsInitialized = true;
            }

            if (!customStyleAndScriptLoaded) {
                customStyleAndScriptLoaded = true;

                appendCSS(response.styles)
                appendJS(response.js)
            }
            
            loadCommonStyleAndScript();

            maybeLoadYaMaps()
        })
    }

    function send(url, options, callback, parse=true) {
        var xhr = new XHR()

        xhr.open('POST', url, true)

        xhr.setRequestHeader('x-token', options.token)
        let optionsCopy = Object.assign({}, options)
        if (optionsCopy.products && typeof optionsCopy.products != 'string') {
            optionsCopy.products = JSON.stringify(optionsCopy.products)
        }
        delete optionsCopy.token
        delete optionsCopy.callback

        xhr.onload = function() {
            let response = this.responseText
            if (parse) {
                response = JSON.parse(response)

                if (response.success === false) {
                    if (response.wantToEat) {
                        let widget = document.getElementById(optionsCopy.main_div_id)
                        if (!widget){
                            alert('cannot find div#'+optionsCopy.main_div_id+', may be Salesbeat is not payed');
                            return;
                        }
                        widget.innerHTML = '<span style="padding: 15px; margin-top: 10px; margin-bottom: 10px; background-color: #666; color: white;">&#x417;&#x430;&#x43A;&#x43E;&#x43D;&#x447;&#x438;&#x43B;&#x441;&#x44F; &#x43E;&#x43F;&#x43B;&#x430;&#x447;&#x435;&#x43D;&#x43D;&#x44B;&#x439; &#x43F;&#x435;&#x440;&#x438;&#x43E;&#x434; &#x432; <a href="https://salesbeat.pro" style="color: white; text-decoration: underline;">Salesbeat</a>.</span>'
                        widget.style.display = ''

                        widget.classList.add('salesbeat-not-payed');

                        console.log(`%c${warnPhrase}: ${response.errorMessage}`, errorStyles)
                    } else {
                        _log(response.errorMessage)
                    }

                    return false
                }
            }

            callback && callback(response)
        }

        xhr.withCredentials = true

        xhr.onerror = function() {
            _log('Произошла совсем непредвиденная ошибка!')
        };

        xhr.send(JSON.stringify(optionsCopy));
    }
    SB.send = send;

    function getFilterPvzByDelivery(deliveries, objectManager) {
        var listBoxItems = [], daysAndPriceEscaped
        for (let delivery of deliveries) {
            if (delivery.type == 'pvz') {
                daysAndPriceEscaped = delivery.days_and_price.replace(' '+getStringFromCharCodes([1076, 1085]), '&nbsp;'+getStringFromCharCodes([1076, 1085])).replace(' '+getStringFromCharCodes([1088, 1091, 1073]), '&nbsp;'+getStringFromCharCodes([1088, 1091, 1073]))
                listBoxItems.push(new sbYmaps.control.ListBoxItem({
                    data: {
                        content: (
                            '<div style="display: inline-block; margin-right:35px; line-height: 1.5em;">' + 
                            [delivery.name, daysAndPriceEscaped].join('&nbsp;&mdash; ') + 
                            '</div>'
                        ),
                        deliveryMethodId: delivery.id
                    }, 
                    state: {
                        selected: true
                    }
                }))
            }
        }

        var btn = new sbYmaps.control.ListBox({
            data: {
                // 'Фильтр по службам доставки',
                content: getStringFromCharCodes([1060, 1080, 1083, 1100, 1090, 1088, 32, 1087, 1086, 32, 1089, 1083, 1091, 1078, 1073, 1072, 1084, 32, 1076, 1086, 1089, 1090, 1072, 1074, 1082, 1080]),
                image: 'data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJmaWx0ZXIiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1maWx0ZXIgZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik00ODcuOTc2IDBIMjQuMDI4QzIuNzEgMC04LjA0NyAyNS44NjYgNy4wNTggNDAuOTcxTDE5MiAyMjUuOTQxVjQzMmMwIDcuODMxIDMuODIxIDE1LjE3IDEwLjIzNyAxOS42NjJsODAgNTUuOThDMjk4LjAyIDUxOC42OSAzMjAgNTA3LjQ5MyAzMjAgNDg3Ljk4VjIyNS45NDFsMTg0Ljk0Ny0xODQuOTdDNTIwLjAyMSAyNS44OTYgNTA5LjMzOCAwIDQ4Ny45NzYgMHoiPjwvcGF0aD48L3N2Zz4=',
                title: ''
            },
            options: {
                // layout: MyListBoxLayout,
                // expanded: true,
                maxWidth: [28, 150, 178],
            },
            items: listBoxItems,
        })

        btn.events.add(['select', 'deselect'], function(e) {
            var listBoxItem = e.get('target')
            var deliveryMethodId = listBoxItem.data.get('deliveryMethodId')

            var listBoxItem = e.get('target');
            var filters = sbYmaps.util.extend({}, btn.state.get('filters'));
            // filterDeliveries(deliveryMethodId, listBoxItem.isSelected())

            var listBoxItem = e.get('target');
            var filters = sbYmaps.util.extend({}, btn.state.get('filters'));
            filters[deliveryMethodId] = listBoxItem.isSelected();
            btn.state.set('filters', filters);

        })

        // Отслеживание изменения поля control.ListBox.state.
        var filterMonitor = new sbYmaps.Monitor(btn.state);

        filterMonitor.add('filters', function(filters) {
            // Применим фильтр к объектам из ObjectManager.
            objectManager.setFilter(getFilterFunction(filters))
        })

        function getFilterFunction(categories){
            return function(obj){
                return categories[obj.properties.deliveryMethodId] !== false 
            }
        }

        return btn
    }

    function drawMap(pvzs, deliveries, mapOptions) {
        mapOptions = mapOptions || {}
        deliveries = deliveries || []

        var withSelectButton = mapOptions.withSelectButton || false
        var mapId = mapOptions.mapId || widgetPvzMapId
        // withSelectButton = withSelectButton || false

        if (!pvzs.length) {
            return
        }

        var objectManager = new sbYmaps.ObjectManager({
            // Чтобы метки начали кластеризоваться, выставляем опцию.
            clusterize: true,
            // ObjectManager принимает те же опции, что и кластеризатор.
            /**
             * Через кластеризатор можно указать только стили кластеров,
             * стили для меток нужно назначать каждой метке отдельно.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/option.presetStorage.xml
             */

            // preset: 'islands#grayClusterIcons',

            // clusterLayout: 'default#pieChart',
            // layout: 'default#pieChart',
            // layout: sbYmaps.layout.PieChart,
            // clusterLayout: sbYmaps.layout.PieChart,

            // preset: 'default#pieChart',
            clusterIconLayout: 'default#pieChart',

            clusterIconPieChartRadius: 25,
            clusterIconPieChartCoreRadius: 15,
            /**
             * Ставим true, если хотим кластеризовать только точки с одинаковыми координатами.
             */
            groupByCoordinates: false,
            /**
             * Опции кластеров указываем в кластеризаторе с префиксом "cluster".
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ClusterPlacemark.xml
             */
            clusterDisableClickZoom: false,
            clusterHideIconOnBalloonOpen: false,
            geoObjectHideIconOnBalloonOpen: false,
            gridSize: 80
        })

        let controls = ['zoomControl']
        // Т.к. баговано работает с нашим popup – просмотром гороов
        if (withSelectButton) {
            controls.push('fullscreenControl')
        }

        deliveries.length && controls.push(getFilterPvzByDelivery(deliveries, objectManager))

        let myCollection = new sbYmaps.GeoObjectCollection()
        
        // Если карта уже была отрисована – уничтожим старую версию
        SB.myMaps[mapId] && SB.myMaps[mapId].destroy()

		let searchControl = new sbYmaps.control.SearchControl({
			options: {
				provider: 'yandex#search'
			}
		});

        // Yandex search bar on Map Only for several sites now
        if (yandexApiKeys[document.location.host]){controls.push(searchControl);}

        var myMap = new sbYmaps.Map(mapId, {
            center: [55.755814, 37.617635],
            zoom: 10,
            controls: controls
        })
        SB.myMaps[mapId] = myMap

        let getPhonesInfo = (phones) => {
            if (!phones || !phones.length) return ''

            let suff = getStringFromCharCodes([1058, 1077, 1083, 1077, 1092, 1086, 1085]) + (phones.length > 1 ? getStringFromCharCodes([1099]) : '') // телефон или телефоны
            let phone_links = []
            for (let phone of phones) {
                phone_links.push(`<a href='tel:${phone}'>${phone}</a>`)
            }
            return `<p> ${suff}: ${phone_links.join(', ')} </p>`
        }

        let getDays = value => {
            if (value == 0) return getStringFromCharCodes([1089, 1077, 1075, 1086, 1076, 1085, 1103])  // сегодня
            let suff = getStringFromCharCodes([1076, 1085, 1077, 1081]) // дней
            let rem = value % 10
            if (rem === 1 && (value < 11 || value > 15)) suff = getStringFromCharCodes([1076, 1077, 1085, 1100]) // день
            else if (rem >= 2 && rem <= 4) suff = getStringFromCharCodes([1076, 1085, 1103]) // дня

            return `${value} ${suff}`
        }

        let getContentHeader = pvz => {
            let price = `${pvz.delivery_price} ${getStringFromCharCodes([1088, 1091, 1073])}.`  // руб.
            if (!pvz.delivery_price) price = getStringFromCharCodes([1073, 1077, 1089, 1087, 1083, 1072, 1090, 1085, 1086])  // бесплатно

            let daysAndPrice = [price]  
            if (pvz.delivery_days !== null) {
                daysAndPrice.splice(0, 0, getDays(pvz.delivery_days))
            }

            return `${pvz.delivery_method_name} ${daysAndPrice.join(', ')}`
        }

        let setObjectProperties = (object, pvz) => {
            if (pvz) {
                // График работы
                let contentBody = ``
                if (withSelectButton) {
                    var deliveryDays = object.properties.deliveryDays || '0'
                    var deliveryPrice = object.properties.deliveryPrice || '0'

                    // добавляем информацию о доступных способах оплаты
                    var payments_allowed = []
                    if (object.properties.payments && Object.keys(object.properties.payments).length){
                        var payment_types = Object.keys(object.properties.payments)
                    } else {
                        var payment_types = []
                    }
                    for (var i=0; i < payment_types.length; i++){
                        if (object.properties.payments[payment_types[i]]){
                            payments_allowed.push(payment_types[i])
                        }
                    }
                    payments_allowed = payments_allowed.join(',')

                    contentBody  += `<p><button type="button" class="select-pvz-btn" 
                                     onclick="SB.selectPvz(event, '${pvz.pvz_id}', '${escape(pvz.address)}', '${pvz.delivery_method_id}', '${escape(pvz.delivery_method_name)}', '${deliveryPrice}', '${deliveryDays}', '${payments_allowed}');">
                        ${getStringFromCharCodes([1042, 1099, 1073, 1088, 1072, 1090, 1100])}</button></p>`  // Выбрать
                }

                contentBody += `<p> ${pvz.pvz_type} </p>
                    <p> ${getStringFromCharCodes([1043, 1088, 1072, 1092, 1080, 1082, 32, 1088, 1072, 1073, 1086, 1090, 1099])}: ${pvz.schedule} </p>
                                   ${getPhonesInfo(pvz.phones)}`
                if (pvz.address_description) contentBody += `<p>${pvz.address_description}</p>`

                object.properties.balloonContentBody = contentBody

                let footer = pvz.address
                object.properties.balloonContentFooter = footer

                object.properties.loaded = true
            } else {
                object.properties.balloonContentHeader = getStringFromCharCodes([1054, 1096, 1080, 1073, 1082, 1072])  // Ошибка
                // "Информация не найдена""
                object.properties.balloonContentBody = getStringFromCharCodes([1048, 1085, 1092, 1086, 1088, 1084, 1072, 1094, 1080, 1103, 32, 1085, 1077, 32, 1085, 1072, 1081, 1076, 1077, 1085, 1072])
            }

            objectManager.objects.balloon.setData(object)
        }

        objectManager.add({
            type: "FeatureCollection", 
            features: pvzs.map(item => ({
                "type": "Feature", 
                "id": `${item.delivery_method_id}:${item.id}`, 
                "geometry": {
                    "type": "Point", 
                    "coordinates": [item.latitude, item.longitude]
                },
                "options": {
                    preset: item.delivery_method_id.startsWith('manual_') ? 'islands#redDotIcon' : 'islands#greenCircleDotIconWithCaption',
                    // Разукрашиваем собственные ПВЗ отдельным цветом
                    iconColor: item.delivery_method_id.startsWith('manual_') ? '#ff0000' : '#64ad1b',
                    openEmptyBalloon: true
                },
                "properties": {
                    balloonContentHeader: getContentHeader(item),
                    deliveryMethodId: item.delivery_method_id,
                    pvzId: item.id,
                    deliveryPrice: item.delivery_price,
                    deliveryDays: item.delivery_days,
                    loaded: false,
                    payments: item.payments
                }}
            ))
        })

        objectManager.events.add('balloonopen', function (e) {
            var objectId = e.get('objectId')
            var object = objectManager.objects.getById(objectId),
                objects, cluster, 
                isCluster = false

            if (object) {
                objects = [object]
            } else {
                cluster = objectManager.clusters.getById(objectId)
                objects = cluster.features
                isCluster = true
            }

            objects = objects.filter(item => !item.properties.loaded)
            if (objects.length) {
                send(pvzInfoUrl, {
                    token: SB.token,
                    pvzs: JSON.stringify(objects.map(item => ({
                        delivery_method_code: item.properties.deliveryMethodId, 
                        pvz_id: item.properties.pvzId
                    })))
                }, (response) => {
                    for (let object of objects) {
                        let item = response.pvzs.find(
                            i => object.properties.deliveryMethodId === i.delivery_method_id && 
                                 object.properties.pvzId === i.pvz_id
                        )
                        setObjectProperties(object, item)
                    }

                    // Почемуто сам он не перерисовывает содержимое балуна кластера, 
                    // если его не заставить сделать это ручками
                    if (isCluster && objectManager.clusters.balloon.isOpen(objectId)) {
                        var clusterData = objectManager.clusters.balloon.getData()
                        objectManager.clusters.balloon.setData(clusterData)
                    }
                })
            }
        })

        myMap.geoObjects.add(objectManager)

        yaMaps.cachedBounds = objectManager.getBounds()
        let boundsSet = false

        // Найдем сохраненный ПВЗ и откроем его балун
        if (withSelectButton) {
            let pvzData = SB.selectedPvz
            if (pvzData) {
                let object = objectManager.objects.getById(`${pvzData.deliveryMethodId}:${pvzData.pvzId}`)

                if (object) {
                    boundsSet = true

                    if (document.getElementById(mapId).clientHeight === 0) {
                        SB.log(`SB не может отобразиться в скрытом элементе (это все яндекс.карты). 
                                Стоит запускать SB.cart_init после отображения div с id="${mapId}".`)
                        return
                    }

                    myMap.panTo(object.geometry.coordinates).then(function() {
                        myMap.setZoom(pvzData.mapZoom || 16)
                        
                        let objectState = objectManager.getObjectState(object.id)
                        if (objectState.isClustered) {
                            // Если метка находится в кластере, выставим ее в качестве активного объекта.
                            // Тогда она будет "выбрана" в открытом балуне кластера.
                            objectManager.clusters.state.set('activeObject', object);
                            objectManager.clusters.balloon.open(objectState.cluster.id);
                        } else if (objectState.isShown) {
                            // Если метка не попала в кластер и видна на карте, откроем ее балун.
                            objectManager.objects.balloon.open(object.id);
                        }
                    })
                }
            }
        }
        if (!boundsSet) {
            myMap.setBounds(objectManager.getBounds(), {
                checkZoomRange: true,
                duration: 500, 
                zoomMargin: 3
            })
        }
    }

    SB.drawMap = drawMap

    SB.showPvzs = function(mainDivId) {
        if (SB.modal.pvzMap.isOpened()) return

        SB.modal.pvzMap.show()

        let widget = SB.widgets.find(item => item.options.main_div_id === mainDivId),
            optionsCopy = Object.assign({}, widget.options)
        
        // Для совместимости со старыми и странными интеграшками
        // (например, textil-domoi.ru передают city_by=ip и тут же передают city_code)
        if (optionsCopy.city_by == 'ip') {
            delete optionsCopy.city_by
            delete optionsCopy.city_code
        }
        // Если код города не передан – попробуем взять его из кук
        if (!optionsCopy.city_code) optionsCopy.city_code = SB.getSelectedCity(optionsCopy)

        send(pvzUrl, optionsCopy, response => {
            if (yaMaps.ready) {
                drawMap(response.pvzs, widget.deliveries)
            } else {
                yaMaps.onReady = () => drawMap(response.pvzs, widget.deliveries)
            }
        })
    }

    SB.onHidePvzs = function() {
        if (SB.myMaps[widgetPvzMapId]) {
            SB.myMaps[widgetPvzMapId].destroy()
        }
    }

    function maybeLoadYaMaps() {
        if (!yaMaps.apiLoadingBegan && document.querySelector('a.salesbeat-pvz-map')) {
            yaMaps.apiLoadingBegan = true
            loadScript(srcMap.yaMaps, () => {
                sbYmaps.ready(() => {
                    yaMaps.ready = true
                    yaMaps.onReady && yaMaps.onReady()
                })
            }, false)
        }
    }

    function toParams(obj) {
        var keys = Object.keys(obj)
        var params = []
        for (var i=0; i < keys.length; i++) {
            params.push(encodeURIComponent(keys[i]) + '=' + encodeURIComponent(obj[keys[i]]))
        }
        return '?' + params.join('&')
    }

    function render(widget) {
        let widgets

        if (widget) {
            widgets = [widget]
        } else {
            widgets = SB.widgets.filter(item => item.loaded)
        }

        for (let item of widgets) {
            let widget = document.getElementById(item.options.main_div_id)

            if (!widget) {
                _log(`Не удалось обнаружить div с id="${item.options.main_div_id}".`)
                return
            }

            widget.innerHTML = item.html;
            widget.style.display = '';

            document.dispatchEvent(new Event(SB.widgetRenderedEventName))
        }
    }

    function appendCSS(styles) {
       var headEl = document.querySelector('head')
       var styleEl = document.createElement('style')
       styleEl.innerHTML = styles

       headEl.appendChild(styleEl)
    }

    function appendJS(js) {
       if (window.salesbeatCustomJs) {return;}
       var headEl = document.querySelector('head')
       var jsEl = document.createElement('script')
       jsEl.innerHTML = js

       headEl.appendChild(jsEl)
       window.salesbeatCustomJs = true
    }

    document.addEventListener('DOMContentLoaded', () => render())

    function loadScript(url, callback, nocache=true) {
        let script = document.createElement("script")
        
        script.onload = function() {
            callback && callback();
        }

        script.src = (nocache && hash) ? `${url}?hash=${hash}` : url
        if (document.querySelector('script[src="' + script.src + '"]')) return;
        
        script.charset = 'UTF-8';
        document.querySelector("head").appendChild(script);
    }
    SB.loadScript = loadScript

    function loadCSS(url, name) {
        var style = document.createElement("link");

        style.href = hash ? `${url}?hash=${hash}` : url
        if (document.querySelector('link[href="' + style.href + '"]')) return;

        style.type = "text/css";
        style.rel = "stylesheet";

        document.querySelector("head").appendChild(style);
    }
    SB.loadCSS = loadCSS

    window.SB = SB

    SB.recieveMessage = function(event) {
        if (typeof event.data === 'string') {
            let data
            
            try {
                data = JSON.parse(event.data)
            } catch(e) {
                return
            }

            if (data.event === 'changeCity') {
                // Временная мера, нужна для того, чтобы установленные ранее куки 
                // с path текущей странички (по умолчанию) не перезатирали новые глобальные (path=/)
                // можно будет удалить через месяцок – 10.2018
                document.cookie = 'sbSelectedCity=; path=' + document.location.pathname.slice(0, -1) + 
                                  '; expires=' + new Date(0).toUTCString()

                SB.setCookie('sbSelectedCity', data.aoguid)
                
                SB.onChangeWidgetCity && SB.onChangeWidgetCity(data.aoguid)
                SB.onChangeCartWidgetCity && SB.onChangeCartWidgetCity(false, data.aoguid)

                document.dispatchEvent(new CustomEvent(SB.cityChangedEventName, {
                    detail: {
                        city_code: data.aoguid,
                        short_name: data.shortName,
                        city_name: data.cityName,
                        region_name: data.regionName
                    }
                }))

                SB.modal.cities.hide()
            }
        }
    }

    SB.onChangeWidgetCity = SB.reinit

    // TODO: Использовать эту штукенцию
    SB.getMaxZIndex = function() {
        var zIndex, z = 0,
            all = document.getElementsByTagName('*');
        for (var i = 0, n = all.length; i < n; i++) {
            zIndex = document.defaultView.getComputedStyle(all[i], null).getPropertyValue("z-index");
            zIndex = parseInt(zIndex, 10);
            z = (zIndex) ? Math.max(z, zIndex) : z;
        }
        if (z < 9999) {
            z = 9999;
        }
        return z;
    }

    window.addEventListener("message", SB.recieveMessage)


    document.addEventListener(SB.widgetRenderedEventName, onAnyWidgetRendered)
    document.addEventListener(SB.cartWidgetRenderedEventName, onAnyWidgetRendered)

    function onAnyWidgetRendered() {
        try {
            if (typeof jQuery !== 'undefined') {
                jQuery(function() {
                    /* Убираем следы действия jq-styler на наш виджет */
                    var inputElements = jQuery('.wdel input[type=checkbox]');
                    if (inputElements && inputElements.styler) {
                        inputElements.styler('destroy');
                    }
                    /* Убираем следы действия jq-forms plugin */
                    jQuery('.wdel span.el-name-wdel-modal-city, .wdel span.el-name-wdel-modal-map').remove()
                    /* Убибаем следы действия jq-uniform plugin */
                    if (jQuery.uniform && jQuery.uniform.restore) {
                        jQuery.uniform.restore('.wdel-checkbox,.wdel2-checkbox')
                    } 
                })
            }
        } catch (e) {
            window.SB.log(e);
        }
    }
})();
